// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-aktuelles-tsx": () => import("./../../../src/pages/aktuelles.tsx" /* webpackChunkName: "component---src-pages-aktuelles-tsx" */),
  "component---src-pages-berufstraeger-tsx": () => import("./../../../src/pages/berufstraeger.tsx" /* webpackChunkName: "component---src-pages-berufstraeger-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-karriere-tsx": () => import("./../../../src/pages/karriere.tsx" /* webpackChunkName: "component---src-pages-karriere-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-leistungen-tsx": () => import("./../../../src/pages/leistungen.tsx" /* webpackChunkName: "component---src-pages-leistungen-tsx" */)
}

